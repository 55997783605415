<template>
  <div>
    <v-overlay :value="isLoadingSpinner" :opacity="`0.5`" zIndex="99999"
      ><v-progress-circular
        indeterminate
        size="70"
        width="7"
        color="primary"
      ></v-progress-circular
    ></v-overlay>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>{{ $t("pos") + " " + $t("route") }}</v-card-title>
      <v-snackbar
        v-model="snackbar"
        :color="snackbar_class"
        elevation="24"
        :timeout="-1"
      >
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            OK
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h6">{{ $t("confirmdelete") }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">{{
              $t("cancel")
            }}</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="700px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.routeId"
                      v-bind:label="$t('route')"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :search-input.sync="search_route"
                      :loading="isSearchingRoute"
                      :items="filtered_routes"
                      hide-no-data
                      hide-selected
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.posId"
                      v-bind:label="$t('pos')"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :search-input.sync="search_pos"
                      :loading="isSearchingPos"
                      :items="filtered_poses"
                      hide-no-data
                      hide-selected
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="selected.periods"
                      v-bind:label="$t('periods')"
                      :items="periodList"
                      multiple
                      filled
                      required
                      :rules="requiredRulesArray"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @click="toggleSelectAllOptions('periods')"
                        >
                          <v-list-item-action>
                            <v-icon
                              :color="
                                selected.periods.length > 0
                                  ? 'indigo darken-4'
                                  : ''
                              "
                              >{{ icon }}</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="selected.s1"
                      v-bind:label="$t('week') + ' 1'"
                      :items="weekdays"
                      item-text="label"
                      item-value="value"
                      multiple
                      filled
                    >
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @click="toggleSelectAllOptions('s1')"
                        >
                          <v-list-item-action>
                            <v-icon
                              :color="
                                selected.s1.length > 0 ? 'indigo darken-4' : ''
                              "
                              >{{ icon_selectall_week1 }}</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="selected.s2"
                      v-bind:label="$t('week') + ' 2'"
                      :items="weekdays"
                      item-text="label"
                      item-value="value"
                      multiple
                      filled
                    >
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @click="toggleSelectAllOptions('s2')"
                        >
                          <v-list-item-action>
                            <v-icon
                              :color="
                                selected.s2.length > 0 ? 'indigo darken-4' : ''
                              "
                              >{{ icon_selectall_week2 }}</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="selected.s3"
                      v-bind:label="$t('week') + ' 3'"
                      :items="weekdays"
                      item-text="label"
                      item-value="value"
                      multiple
                      filled
                    >
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @click="toggleSelectAllOptions('s3')"
                        >
                          <v-list-item-action>
                            <v-icon
                              :color="
                                selected.s3.length > 0 ? 'indigo darken-4' : ''
                              "
                              >{{ icon_selectall_week3 }}</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="selected.s4"
                      v-bind:label="$t('week') + ' 4'"
                      :items="weekdays"
                      item-text="label"
                      item-value="value"
                      multiple
                      filled
                    >
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @click="toggleSelectAllOptions('s4')"
                        >
                          <v-list-item-action>
                            <v-icon
                              :color="
                                selected.s4.length > 0 ? 'indigo darken-4' : ''
                              "
                              >{{ icon_selectall_week4 }}</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.status"
                      v-bind:label="$t('salesforce.status')"
                      :items="statusValues"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                :loading="isSaving"
                text
                @click="onSaveClick(selected)"
              >
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn
              v-if="importExcelTemplatePathName"
              color="blue darken-1"
              @click="downloadExcelTemplate(importExcelTemplatePathName)"
            >
              <b-icon icon="download" aria-hidden="true"></b-icon> Plantilla
              Import
            </v-btn>
            <v-btn
              color="success"
              dark
              class="ml-2"
              :loading="isSelecting"
              @click="onUploadExcelClick"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              {{ buttonText }}
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept=".xls,.xlsx"
              @change="onFileChanged"
            />
            <v-btn color="warning" dark class="ml-2" @click="downloadExcel">
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Excel
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRefroutes"
              id="pos_routes_table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="data"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaldata"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import _ from "lodash";

export default {
  name: "posroutes",
  data: function() {
    return {
      importExcelTemplatePathName: null,
      filterlist_fetch_url: "pos/posroutes/getfilterlist",
      filterable_col_list: [
        "routeId",
        "route",
        "brand",
        "visitType",
        "surveyId",
        "survey",
        "posId",
        "pos",
        "address_label",
        "periods",
        "week1",
        "week2",
        "week3",
        "week4",
        "status"
      ],
      filterCriteria: {
        routeId: {},
        route: {},
        brand: {},
        visitType: {},
        surveyId: {},
        survey: {},
        posId: {},
        pos: {},
        periods: {},
        week1: {},
        week2: {},
        week3: {},
        week4: {},
        status: {}
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      showEmpty: false,

      totaldata: 0,
      data: [],
      weekdays: [],
      statusValues: [],
      // visitTypes: [],
      periodsCount: 13,
      periodList: [],
      brandList: [],
      surveyList: [],
      selectedSurveyList: [],

      /********** others **********/
      isSelecting: false,
      selectedFile: null,
      defaultButtonText: "Import Excel",
      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      valid: true,
      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],
      selected: null,
      editedIndex: -1,
      showEdit: false,

      search_route: null,
      isSearchingRoute: false,
      filtered_routes: [],
      search_brand: null,
      isSearchingBrand: false,
      filtered_brands: [],
      search_survey: null,
      isSearchingSurvey: false,
      filtered_surveys: [],
      search_pos: null,
      isSearchingPos: false,
      filtered_poses: [],

      dialogDelete: false,
      deleteItem: -1,

      isSaving: false,

      /******************** Loading Spinner Overlay ********************/
      isLoadingSpinner: false
    };
  },
  watch: {
    async search_route(val) {
      this.searchValues("route", val);
    },
    async search_brand(val) {
      this.searchValues("brand", val);
    },
    async search_survey(val) {
      this.searchValues("survey", val);
    },
    async search_pos(val) {
      this.searchValues("pos", val);
    }
  },
  computed: {
    likesAllPeriods() {
      return this.selected.periods.length === this.periodList.length;
    },
    icon() {
      if (this.likesAllPeriods) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    likesAllWeek1() {
      return this.selected.s1.length === this.weekdays.length;
    },
    icon_selectall_week1() {
      if (this.likesAllWeek1) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    likesAllWeek2() {
      return this.selected.s2.length === this.weekdays.length;
    },
    icon_selectall_week2() {
      if (this.likesAllWeek2) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    likesAllWeek3() {
      return this.selected.s3.length === this.weekdays.length;
    },
    icon_selectall_week3() {
      if (this.likesAllWeek3) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    likesAllWeek4() {
      return this.selected.s4.length === this.weekdays.length;
    },
    icon_selectall_week4() {
      if (this.likesAllWeek4) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    col_routeId() {
      return {
        key: "routeId",
        title: "ID " + this.$t("route"),
        field: "routeId",
        align: "left",
        width: 100,
        sortBy: "",
        fixed: "left",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["routeId"]["from"]}
                  value={this.filterCriteria["routeId"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["routeId"]["to"]}
                  value={this.filterCriteria["routeId"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "routeId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_route() {
      return {
        key: "route",
        title: this.$t("route") + " " + this.$t("brands.name"),
        field: "route",
        align: "left",
        width: 200,
        sortBy: "",
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.route_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["route"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`route`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["route"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "route")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_posId() {
      return {
        key: "posId",
        title: "ID " + this.$t("pos"),
        field: "posId",
        align: "left",
        width: 100,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["posId"]["from"]}
                  value={this.filterCriteria["posId"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["posId"]["to"]}
                  value={this.filterCriteria["posId"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "posId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_pos() {
      return {
        key: "pos",
        title: this.$t("pos") + " " + this.$t("brands.name"),
        field: "pos",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.pos_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["pos"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`pos`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["pos"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "pos")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_address_label() {
      return {
        key: "address_label",
        title: this.$t("address"),
        field: "address_label",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.address_label}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["address_label"]}
                  on-input={value =>
                    (this.filterCriteria["address_label"] = value)
                  }
                  placeholder="Search Address Name"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "address_label")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_periods() {
      return {
        key: "periods",
        title: this.$t("periods"),
        field: "periods",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.periods_label}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            let periods = [];
            for (let i = 1; i <= this.periodsCount; i++) {
              periods.push(i.toString());
            }
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["periods"]}
                  dataList={periods}
                  filterCriteria={this.filterCriteria["periods"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "periods")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_week1() {
      return {
        key: "week1",
        title: this.$t("week") + " 1",
        field: "week1",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.weeklabel_1}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["week1"]}
                  dataList={this.weekdays}
                  filterCriteria={this.filterCriteria["week1"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "week1")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_week2() {
      return {
        key: "week2",
        title: this.$t("week") + " 2",
        field: "week2",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.weeklabel_2}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["week2"]}
                  dataList={this.weekdays}
                  filterCriteria={this.filterCriteria["week2"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "week2")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_week3() {
      return {
        key: "week3",
        title: this.$t("week") + " 3",
        field: "week3",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.weeklabel_3}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["week3"]}
                  dataList={this.weekdays}
                  filterCriteria={this.filterCriteria["week3"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "week3")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_week4() {
      return {
        key: "week4",
        title: this.$t("week") + " 4",
        field: "week4",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.weeklabel_4}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["week4"]}
                  dataList={this.weekdays}
                  filterCriteria={this.filterCriteria["week4"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "week4")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_status() {
      return {
        key: "status",
        title: this.$t("status"),
        field: "status",
        align: "left",
        sortBy: "",
        width: 150,
        fixed: "right",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-checkbox
                  v-model={this.filterCriteria["status"]["active"]}
                  label="active"
                  value="active"
                ></v-checkbox>
                <v-checkbox
                  v-model={this.filterCriteria["status"]["inactive"]}
                  label="inactive"
                  value="inactive"
                ></v-checkbox>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "status")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_brands() {
      return {
        key: "brands",
        title: "Marcas",
        field: "brands",
        align: "center",
        width: 150,
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.brandNames}</span>;
        }
      };
    },
    col_actions() {
      return {
        key: "actions",
        title: this.$t("edititem"),
        field: "actions",
        align: "center",
        width: 100,
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-icon
                medium
                color="primary"
                class="mr-2"
                on-click={() => this.onItemEdit(row)}
              >
                mdi-pencil
              </v-icon>
              <v-icon
                medium
                color="red"
                class="mr-2"
                on-click={() => this.onItemRemove(row)}
              >
                mdi-delete
              </v-icon>
            </div>
          );
        }
      };
    },
    columns() {
      return [
        this.col_routeId,
        this.col_route,
        this.col_posId,
        this.col_pos,
        this.col_address_label,
        this.col_periods,
        this.col_week1,
        this.col_week2,
        this.col_week3,
        this.col_week4,
        this.col_status,
        this.col_brands,
        this.col_actions
      ];
    },
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    }
  },
  methods: {
    toggleSelectAllOptions(flag) {
      if (flag === "periods") {
        if (this.likesAllPeriods) {
          this.selected.periods = [];
        } else {
          this.selected.periods = this.periodList;
        }
      } else if (flag === "s1") {
        if (this.likesAllWeek1) {
          this.selected.s1 = [];
        } else {
          this.selected.s1 = this.weekdays.map(item => item.value);
        }
      } else if (flag === "s2") {
        if (this.likesAllWeek2) {
          this.selected.s2 = [];
        } else {
          this.selected.s2 = this.weekdays.map(item => item.value);
        }
      } else if (flag === "s3") {
        if (this.likesAllWeek3) {
          this.selected.s3 = [];
        } else {
          this.selected.s3 = this.weekdays.map(item => item.value);
        }
      } else if (flag === "s4") {
        if (this.likesAllWeek4) {
          this.selected.s4 = [];
        } else {
          this.selected.s4 = this.weekdays.map(item => item.value);
        }
      }
    },
    async searchValues(fieldname, val) {
      if (!val) val = "";
      let fetch_url = `${this.filterlist_fetch_url}?column=${fieldname}&isFullText=true&filterValue=${val}`;
      this.isSearchingRoute = true;
      const resp = await ApiService.get(fetch_url);
      if (fieldname === "route") {
        this.filtered_routes = resp.data;
      } else if (fieldname === "brand") {
        this.filtered_brands = resp.data;
      } else if (fieldname === "survey") {
        this.filtered_surveys = resp.data;
      } else if (fieldname === "pos") {
        this.filtered_poses = resp.data;
      }
      this.isSearchingRoute = false;
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (cancelFilterKey === "address_label") {
        this.filterCriteria[cancelFilterKey] = null;
      } else {
        this.filterCriteria[cancelFilterKey] = {};
      }
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#pos_routes_table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        "#pos_routes_table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "pos/posroutes";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.data = resp.data;
        this.spv_list = resp.spv_list;

        if (this.data.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaldata = resp.total;
        // this.brandList = resp.brandList;
        // this.surveyList = resp.surveyList;
        this.weekdays = resp.weekdays;
        this.statusValues = resp.statusValues;
        this.periodsCount = resp.periodsCount;
        // this.visitTypes = resp.visitTypes;
        this.importExcelTemplatePathName = resp.importExcelTemplatePathName;
        this.periodList = [];
        for (let i = 1; i <= this.periodsCount; i++) this.periodList.push(i);
        this.close();
      } catch (error) {
        this.close();
      }
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    async createSelectedModel(item) {
      let model = {
        id: _.get(item, "id"),
        routeId: _.get(item, "routeId"),
        route: _.get(item, "route"),
        // brandId: _.get(item, "brandId"),
        // brand: _.get(item, "brand"),
        // surveyIds: _.get(item, "surveyIds"),
        // surveys: _.get(item, "surveys"),
        posId: _.get(item, "posId"),
        pos: _.get(item, "po"),
        periods: _.get(item, "periods"),
        status: _.get(item, "status"),
        // visitType: _.get(item, "visitType"),
        weekdetails: _.get(item, "weekdetails"),
        s1: _.get(item, "groupS1"),
        s2: _.get(item, "groupS2"),
        s3: _.get(item, "groupS3"),
        s4: _.get(item, "groupS4"),
        // brand_list: _.get(item, "brand_list"),
        dataIds: _.get(item, "dataIds")
      };
      // model.brand_list = JSON.parse(JSON.stringify(item.brand_list));
      if (model?.route) {
        await this.searchValues("route", model.route?.name);
      } else {
        await this.searchValues("route", "");
      }
      if (model?.pos) {
        await this.searchValues("pos", model.pos?.name);
      } else {
        await this.searchValues("pos", "");
      }
      // this.searchValues("brand", "");
      // this.searchValues("survey", "");
      for (let i = 1; i <= 4; i++) {
        if (model[`s${i}`]) {
          model[`s${i}`] = _.uniq(model[`s${i}`].split(","));
        } else {
          model[`s${i}`] = [];
        }
      }
      if (model.status === undefined) {
        model.status = "active";
      }
      // if (model.visitType === undefined) {
      //   model.visitType = "PRESENT";
      // }
      // this.selectedSurveyList = [];
      // model.brand_list.map((el, index) => {
      //   this.selectedSurveyList[index] = this.surveyList.filter(
      //     (surlistItem) => surlistItem.brandId === el.brandId
      //   );
      //   return el;
      // });
      // console.log(this.selectedSurveyList);
      console.log(model);
      return model;
    },
    onClickRemoveBrandSurveys(removeIndex) {
      if (this.selected.brand_list.length > 1) {
        this.selected.brand_list = [
          ...this.selected.brand_list.slice(0, removeIndex),
          ...this.selected.brand_list.slice(removeIndex + 1)
        ];
        this.selectedSurveyList = [
          ...this.selectedSurveyList.slice(0, removeIndex),
          ...this.selectedSurveyList.slice(removeIndex + 1)
        ];
      }
    },
    onClickAddBrandSurveys() {
      if (this.selected) {
        let push_item = { brandId: null, surveyIds: [], surveys: [] };
        this.selected.brand_list.push(push_item);
      }
    },
    onChangeBrand(selectedBrandId, index) {
      console.log(selectedBrandId);
      console.log(this.surveyList);
      if (selectedBrandId) {
        this.selectedSurveyList[index] = this.surveyList.filter(
          el => el.brandId === selectedBrandId
        );
      } else {
        this.selectedSurveyList[index] = this.surveyList;
      }
      console.log(this.surveyList);
    },
    async onItemEdit(item) {
      this.selected = await this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    async onSaveClick(item) {
      console.log(item);
      if (this.$refs.form.validate()) {
        this.isSaving = true;
        let body = item;
        await ApiService.post(`pos/posroutes/edit`, body);
        logInfo("éxito");
        this.showEdit = false;
        this.isSaving = false;
        this.getDataFromApi();
      }
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deleteItem = null;
      });
    },
    onItemRemove(item) {
      this.deleteItem = item;
      console.log(this.deleteItem);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      if (this.deleteItem) {
        this.closeDelete();
        // const { routeId, posId, brandId, surveyId } = this.deleteItem;
        // let deleteCriteria = `${routeId}/${posId}/${brandId}/${surveyId}`;
        const { routeId, posId } = this.deleteItem;
        let deleteCriteria = `${routeId}/${posId}`;
        ApiService.delete(`pos/posroutes/${deleteCriteria}`).then(() => {
          logInfo("eliminada");
          this.getDataFromApi();
        });
      }
    },
    async downloadExcel() {
      // this.show();
      this.isLoadingSpinner = true;
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "pos/posroutes/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const response = await ApiService.get(fetch_url, {
          responseType: "blob"
        });
        download(response, "posroutes.xlsx");
        // this.close();
        this.isLoadingSpinner = false;
      } catch (error) {
        // this.close();
        this.isLoadingSpinner = false;
      }
    },
    onUploadExcelClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: false }
      );
      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      console.log(this.selectedFile);
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isSelecting = true;
        this.isLoadingSpinner = true;
        let data = new FormData();
        data.append("file", this.selectedFile);
        this.$refs.uploader.value = "";
        try {
          const response = await ApiService.post(
            `pos/posroutes/uploadexcel`,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported !`;
            this.snackbar = true;
            this.getDataFromApi();
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
            this.getDataFromApi();
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelecting = false;
          this.isLoadingSpinner = false;
        } catch (error) {
          console.log(error);
          this.isSelecting = false;
          this.isLoadingSpinner = false;
        }
      }
    },
    async downloadExcelTemplate(downloadFile) {
      let downloadfile = ApiService.getExcelTemplateFileUri(downloadFile);
      try {
        const response = await ApiService.get(downloadfile, {
          responseType: "arraybuffer"
        });
        download(response, downloadFile);
      } catch (error) {
        // this.close();
      }
    },
    init() {
      this.getDataFromApi();
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style>
table.sub_data_table {
  width: 100%;
}
table.sub_data_table tbody tr td {
  border: none !important;
}
.ve-table
  .ve-table-container
  .ve-table-border-y
  table.sub_data_table
  tbody
  tr
  td:first-child {
  border: none !important;
  border-bottom: 1px solid #9f9f9f !important;
}
.ve-table.ve-table-border-around
  .ve-table-container
  table.ve-table-content.ve-table-border-x
  table.sub_data_table
  tr:last-child
  > td {
  border: none !important;
}
</style>
